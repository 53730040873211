import gql from 'graphql-tag';

export const fragmentProduct = `
  fragment BaseProduct on ProductInterface {
    attribute_set_id
    bullet_upto_visibility
    compatibility_list {
      url
      name
    }
    conformity_marks
    delivery_cut_off
    headline
    id
    manufacturer_address
    manufacturer_anchor
    name
    new_to_market
    not_compatible_with_hp_plus
    per_print_hover_text
    per_page_price
    per_print_type
    rating_summary
    review_count
    safety_information
    sku
    small_image {
      url
    }
    specification_product_type
    specification_brand
    standard_interfaces
    stock_status
    trade_in_start_date
    trade_in_end_date
    trade_in_lightbox_html
    trade_in_value
    uid
    url_key
    visibility
    warranty_ext_start_date
    warranty_ext_end_date
    warranty_ext_lightbox_html
    warranty_ext_years
  }
`;

export const fragmentCashback = `
  fragment Cashback on ProductInterface {
    cashback_lightbox_html
    cashback_value
    cashback_catch
    cashback_end_date
    cashback_start_date
    cashback_warranty_association
  }
`;

export const fragmentStock = `
  fragment Stock on ProductInterface {
    special_stock {
      can_show_estimate
      delivery_lead_times {
        from
        to
      }
      delivery_text
      is_special_delivery
      is_christmas_time
    }
    stock {
      low_stock_date
      qty
      stock_show_in
      is_in_stock
      max_sale_qty
    }
    only_x_left_in_stock
    stock_status
  }
`;

export const fragmentPrice = `
  fragment Price on ProductInterface {
    price {
      regularPrice {
        adjustments {
          amount {
            value
          }
        }
        amount {
          value
        }
      }
      minimalPrice {
        adjustments {
          amount {
            value
          }
        }
        amount {
          value
        }
      }
    }
  }
`;

// hp_eprint
// colour_all_in_one_functions
export const fragmentPrinterSpecification = `
  fragment PrinterSpecification on ProductInterface {
    keyboard_layout
    height
    width
    depth
    package_weight
    product_dimensions_when_open
    windows_operating_systems
    server_operating_systems
    mac_operating_systems
    duplex_functions
    printing
    time_to_first_page_black
    time_to_first_page_colour
    print_speed_black_normal
    print_speed_colour_normal
    duplex_printing_modes
    duplex_print_speed_black
    duplex_print_speed_colour
    print_resolution
    maximum_resolution
    recommended_duty_cycle
    maximum_duty_cycle
    scanning
    scan_speed_black
    scan_speed_colour
    duplex_scan_speed_black
    duplex_scan_speed_colour
    optical_scanning_resolution
    maximum_scan_resolution
    maximum_scan_area
    scanner_type
    document_formats_supported
    scan_to
    maximum_iso_a_series_paper
    standard_input_capacity
    maximum_input_capacity
    standard_tray_media_types
    standard_tray_media_weight
    number_of_standard_paper
    maximum_number_of_paper_trays
    multi_purpose_tray_capacity
    multi_purpose_tray_media
    auto_document_feeder_adf
    duplex_media_weight
    standard_output_capacity
    maximum_output_capacity
    iso_a_series_sizes_a0_a9
    iso_b_series_sizes_b0_b9
    iso_c_series_sizes_c0_c9
    envelopes_sizes
    custom_media_width
    custom_media_length
    maximum_print_size
    non_iso_print_media_sizes
    photo_paper_sizes
    borderless_printing_media
    standard_printer_languages
    input_capacity_tray_2
    maximum_internal_memory
    processor_frequency
    display
    included_black__color_cmyk
    internal_memory
    display_diagonal
    energy_star_typical
    printing_noise_level
    other_operating_systems
    wi_fi_standards
    bluetooth
    print_resolution_colour
    print_resolution_black
    noise_level
    usb_cable_included
    display_type
    operating_acoustic_emission
    memory_upgrade
    package_dimensions_w_x_d_x_h
    near_field_communication_nfc
    usb_connector
    mobile_operating_systems
    compatible_memory_cards
    sku_pack_component
    trade_in_start_date
    free_item_start_date
    compatible_cartridges_available_filter
    print_speed_filters_filter
    operating_systems_for_filters_filter
  }
`;

export const fragmentAlternativeProduct = `
  fragment AlternativeProduct on ProductInterface {
    cartridge_condition
    sku_pack_component
    return_program_hover_over
    status
    visibility
    capacity
    yields {
      color
      swatches
      yield_text
      yield
    }
    __typename
    ...BaseProduct
  }
`;

export default gql`
  query productDetails(
    $search: String = "",
    $filter: ProductAttributeFilterInput,
    $pageSize: Int = 10,
    $currentPage: Int = 1,
    $sort: ProductAttributeSortInput
  ) {
    products(search: $search, filter: $filter, sort: $sort, pageSize: $pageSize, currentPage: $currentPage) {
      items {
        thumbnail {
          url
        }
        url_rewrites {
          url
        }
        categories {
          url_suffix
          breadcrumbs {
            category_name
            category_url_path
          }
        }
        media_gallery_entries {
          file
          types
          video_content {
            video_url
            video_title
          }
        }
        meta_description
        meta_keyword
        meta_title
        description {
          html
        }
        reasons_to_buy
        hp_dynamic_security
        yields {
          color
          swatches
          yield_text
          yield
        }
        manufacturers_code
        duty_cycle
        capacity
        group
        specification_brand
        specification_colour
        specification_product_type
        short_name
        return_program_hover_over
        return_program_hover_show
        bullet_upto_visibility
        cartridge_condition
        due_in_date
        price_tiers {
          final_price {
            value
          }
          final_price_incl_tax {
            value
          }
          quantity
        }
        type_id
        status
        canonical_url
        printer_main_functions_bullet
        printer_feature_bullet
        category_link {
          category_link
        }
        printer_condition
        review_stars {
          percent
          count
        }
        alternative_product {
          arrow_rule
          price_difference_incl_tax
          price_difference
          yields_difference
          stock_status
          only_x_left_in_stock
          ...AlternativeProduct
          ...Price
          ...Stock
        }
        related_printer {
          name
          ...Price
          printer_condition
          sku
          url_path
        }
        buy_together {
          name
          ...Price
          sku
          small_image {
            url
          }
          stock {
            is_in_stock
          }
          yields {
            color
            swatches
            yield_text
            yield
          }
          url_key
          url_suffix
        }
        products_in_range {
          name
          ...Price
          sku
          small_image {
            url
          }
          url_key
          url_suffix
        }
        __typename
        ...BaseProduct
        ...Cashback
        ...Stock
        ...Price
        ...PrinterSpecification
      }
    }
  }
  ${fragmentProduct}
  ${fragmentCashback}
  ${fragmentStock}
  ${fragmentPrinterSpecification}
  ${fragmentPrice}
  ${fragmentAlternativeProduct}
`;
