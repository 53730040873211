import isServer from '~/helpers/isServer';

export function handleGTMEvent(event: string, data: object) {
  if (isServer) {
    return;
  }

  window.dataLayer?.push({
    event,
    ...data,
  });
}
