


















import {
  computed, ComputedRef,
  defineComponent,
} from '@nuxtjs/composition-api';

import { MODAL_NAMES, SimpleModal } from '~/components/common/SimpleModal';
import { useUiState } from '~/composables';
import { ProductInterface } from '~/modules/GraphQL/types';

export default defineComponent({
  name: 'SwitchAndSellModal',
  components: {
    SimpleModal,
    SwitchAndSellContent: () => import('./components/Content/Content.vue'),
  },
  setup() {
    const { modal } = useUiState();

    const product: ComputedRef<ProductInterface> = computed(() => modal.value.props?.product);
    const quantity: ComputedRef<number> = computed(() => (modal.value.props?.qty ? Number(modal.value.props?.qty) : 1));
    const categoryName: ComputedRef<string> = computed(() => modal.value.props?.categoryName);
    const isVisible = computed(() => modal.value?.identifier === MODAL_NAMES.switchAndSell);

    return {
      product,
      quantity,
      isVisible,
      categoryName,
      name: MODAL_NAMES.switchAndSell,
    };
  },
});
