






















import {
  computed,
  defineComponent,
} from '@nuxtjs/composition-api';

import { MODAL_NAMES, SimpleModal } from '~/components/common/SimpleModal';
import { useUiState } from '~/composables';

export default defineComponent({
  name: 'ManufacturerModal',
  components: {
    SimpleModal,
  },
  setup() {
    const { modal, modalAdditional } = useUiState();

    return {
      modalProps: computed(() => (modalAdditional.value?.props?.tooltipContent || modal.value?.props?.tooltipContent || {})),
      name: MODAL_NAMES.manufacturerInfo,
    };
  },
});
