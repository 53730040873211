import { computed, useContext } from '@nuxtjs/composition-api';

import { useConfig } from '~/composables';
import formatCurrency from '~/helpers/formatCurrency';

import type { UseMagentoConfigurationInterface } from './UseMagentoConfiguration';

/**
 * Allows getting the Magento's major
 * definitions, e.g., the selected currency, store, locale, and config object.
 *
 * See the {@link UseMagentoConfigurationInterface} for a list of methods and values available in this composable.
 */
export function useMagentoConfiguration(): UseMagentoConfigurationInterface {
  const {
    app: {
      $vsf: {
        $magento: { config },
      },
    },
  } = useContext();

  const { config: storeConfig } = useConfig();

  const selectedCurrency = computed<string | undefined>(() => config.state.getCurrency());
  const selectedLocale = computed<string | undefined>(() => config.state.getLocale());
  const selectedStore = computed<string | undefined>(() => config.state.getStore());
  const magentoBaseUrl = computed<string | undefined>(() => config.magentoBaseUrl);
  const currentCurrencySymbol = computed(() => {
    try {
      return (0).toLocaleString(
        selectedLocale.value.replace(/[!"$-/:-?[\]^_`{-~]/, '-'),
        {
          style: 'currency',
          currency: selectedCurrency.value,
          minimumFractionDigits: 0,
          maximumFractionDigits: 0,
        },
      )
        .replace(/\d/g, '')
        .trim();
    } catch {
      return selectedLocale.value;
    }
  });

  const getPriceWithCurrency = (value: string | number, options: Intl.NumberFormatOptions = {}): (string | number) => {
    if (!value || !selectedLocale.value || !selectedCurrency.value) {
      return value;
    }

    return formatCurrency(value, selectedLocale.value, { currency: selectedCurrency.value, ...options });
  };

  return {
    storeConfig,
    selectedCurrency,
    selectedLocale,
    selectedStore,
    currentCurrencySymbol,
    magentoBaseUrl,
    getPriceWithCurrency,
  };
}

export * from './UseMagentoConfiguration';
export default useMagentoConfiguration;
