export default function getObjectByString(o: any, s: string): Object | null {
  if (!o) {
    return null;
  }

  let object = o;
  let string = s.replace(/\[(\w+)]/g, '.$1');
  string = string.replace(/^\./, '');
  const keys = string.split('.');

  for (let i = 0, n = keys.length; i < n; ++i) {
    const k = keys[i];

    if (k in object) {
      if (object[k] !== null && typeof object[k] === 'object') {
        object = object[k];
      }
    }
  }

  return object;
}
